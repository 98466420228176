import { Component, OnInit } from '@angular/core';

import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Global } from '../../global'
import { saveAs } from "file-saver";

@Component({
  selector: 'app-espelho-ponto-consolidado-funcionario',
  templateUrl: './espelho-ponto-consolidado-funcionario.component.html',
  styleUrls: ['./espelho-ponto-consolidado-funcionario.component.css']
})
export class EspelhoPontoConsolidadoFuncionarioComponent implements OnInit {
  loading: boolean = false;
  loadAutocomplete: boolean = false;
  listaMeses: any = [];
  filtro: any = { };

  keyword = 'nome_sobrenome';
  data: any = [];
  naoEncontrado: String = "Não encontrado"

  constructor(private apiService: ApiService, private router: Router, private global: Global) { }

  ngOnInit() {
    this.obterMeses();
    this.filtro.ano = new Date().getFullYear();
  }

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: "Janeiro" },
      { id: 2, nome: "Fevereiro" },
      { id: 3, nome: "Março" },
      { id: 4, nome: "Abril" },
      { id: 5, nome: "Maio" },
      { id: 6, nome: "Junho" },
      { id: 7, nome: "Julho" },
      { id: 8, nome: "Agosto" },
      { id: 9, nome: "Setembro" },
      { id: 10, nome: "Outubro" },
      { id: 11, nome: "Novembro" },
      { id: 12, nome: "Dezembro" },
    ];
    this.filtro.mes = new Date().getMonth() + 1;
    this.loading = false;
  }

  selecionarFuncionario(item) {
    this.filtro.funcionario = item.uuid
  }

  onChangeSearch(val: string) {
    this.loadAutocomplete = true;
    debugger
      this.apiService.Get("Funcionarios/Autocomplete?query=" + val).then(
        (result) => {
          let dado: any = result;
          dado.map((item, index) => {
            dado[index].nome_sobrenome = `${item.nome} ${item.sobrenome}`
          });
          this.data = dado;
          this.loadAutocomplete = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loadAutocomplete = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  }
  
  limparCampo(){
    this.data = [];
    this.filtro.funcionario = null;
  }

  imprimir = () => {
    this.loading = true;
    debugger
    this.validarFiltros().then(
      result => {
        var txt = '';
        txt = "ano="+this.filtro.ano+"&mes="+this.filtro.mes;

        this.apiService.Get(`Funcionarios/${this.filtro.funcionario}/EspelhoPonto?${txt}`).then(
          result => {
          },
          error => {
            if(error.status ==200){
              let dataAtual = new Date();
              let mesAtual = dataAtual.getMonth() + 1;
              let anoAtual = dataAtual.getFullYear();
              var result = saveAs(
                error["error"]["text"],
                `espelho-de-ponto-consolidado-funcionario-${mesAtual}/${anoAtual}.pdf`
              );
              this.loading = false;
              return
            }
            this.exibeErro(error);
          }
        )
      },        
      error => {
        this.exibeErro(error);
      }
    )
  };

  validarFiltros = () => {
    return new Promise((resolve, reject) => {
      if (this.filtro.ano == null || this.filtro.ano == 0 || this.filtro.ano == "0" || this.filtro.ano == "") {
        reject("Informe o ano")
      }
      if (this.filtro.mes == null || this.filtro.mes == 0 || this.filtro.mes == "0") {
        reject("Informe o mês")
      }
      if (this.filtro.funcionario == null || this.filtro.funcionario == 0) {
        reject("Informe o funcionário")
      }
      resolve('Tudo certo!')
    })
  };
  
  exibeErro = (erro:any) => {
    this.loading = false;
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: erro.error.mensagem
    });
  };

}
