import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ModalTrocaSenhaComponent } from './components/modal-troca-senha/modal-troca-senha.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr)
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

//import { AppConfig } from './services/app.config';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';

//import { ApiService } from './services/api.service';
import { NgxViacepModule, NgxViacepService } from "@brunoc/ngx-viacep";
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgAutoCompleteModule } from "ng-auto-complete";
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { NgxMaskModule, IConfig } from 'ngx-mask'
//import { MatFormComponentsModule } from 'ngx-mat-form-components';

//imports
import { appRoutes } from './routes';
import { AgmCoreModule } from '@agm/core'

//components
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';

//pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuarioComponent } from './pages/cadastros/usuario/usuario.component';
import { Global } from './global';
import { ApiService } from './services/api.service';
import { AppConfig } from './services/app.config';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { UnidadeComponent } from './pages/cadastros/unidade/unidade.component';
import { FuncionarioComponent } from './pages/cadastros/funcionario/funcionario.component';
import { CargoComponent } from './pages/cadastros/cargo/cargo.component';
import { TurnoComponent } from './pages/cadastros/turno/turno.component';
import { VinculoComponent } from './pages/cadastros/vinculo/vinculo.component';
import { PermissoesComponent } from './pages/permissoes/permissoes.component';
import { EscalaComponent } from './pages/escala/escala.component';
import { EscalaAcompanhamentoComponent } from './pages/escala_acompanhamento/escala_acompanhamento.component';
import { DiarioFrequenciaComponent } from './pages/diario-frequencia/diario-frequencia.component';
import { MotivoAlteracaoComponent } from './pages/cadastros/motivo-alteracao/motivo-alteracao.component';
import { FeriadoComponent } from './pages/cadastros/feriado/feriado.component';
import { NovoClienteComponent } from './pages/novo-cliente/novo-cliente.component';
import { ApontamentoMassaComponent } from './pages/apontamento-massa/apontamento-massa.component';
import { AcompanhamentoPontosComponent } from './pages/acompanhamento-pontos/acompanhamento-pontos.component';

//modal
import { ModalCadSetorComponent } from './components/modal-cad-setor/modal-cad-setor.component';
import { ModalFrequenciaComponent } from './components/modal-frequencia/modal-frequencia.component';
import { ModalAcompanhamentoComponent } from './components/modal-acompanhamento/modal-acompanhamento.component';
import { ModalAprovaTrocaComponent } from './components/modal-aprova-troca/modal-aprova-troca.component';
import { AlteracaoEscalaComponent } from './pages/alteracao-escala/alteracao-escala.component';
import { EspelhoPontoComponent } from './pages/espelho-ponto/espelho-ponto.component';
import { ModalCopiaEscalaComponent } from './components/modal-copia-escala/modal-copia-escala.component';
import { ConfiguracaoComponent } from './pages/configuracao/configuracao.component';
import { ModalBiometriaComponent } from './components/modal-biometria/modal-biometria.component';
import { ExportacaoPontoComponent } from './pages/exportacao-ponto/exportacao-ponto.component';
import { AprovarFotosComponent } from './pages/aprovar-fotos/aprovar-fotos.component';
import { PontosReprovadosComponent } from './pages/pontos-reprovados/pontos-reprovados.component';
import { DashboardRegistrosComponent } from './pages/dashboard-registros/dashboard-registros.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { ListarFuncionariosComponent } from './pages/funcionarios/listar-funcionarios/listar-funcionarios.component';
import { EditarFuncionarioComponent } from './pages/funcionarios/editar-funcionario/editar-funcionario.component';
import { ModalApontamentoComponent } from './components/modal-apontamento/modal-apontamento.component';
import { ModalDetalheIndicadorComponent } from './components/modal-detalhe-indicador/modal-detalhe-indicador.component';
import { EspelhoPontoConsolidadoComponent } from './pages/espelho-ponto-consolidado/espelho-ponto-consolidado.component';
import { ModalDetalheIndicadorPontoComponent } from './components/modal-detalhe-indicador-ponto/modal-detalhe-indicador-ponto.component';
import { ModalApontamentoMassaComponent } from './components/modal-apontamento-massa/modal-apontamento-massa.component';
import { TemplateEscalaComponent } from './pages/template-escala/template-escala.component';
import { EspelhoPontoConsolidadoFuncionarioComponent } from './pages/espelho-ponto-consolidado-funcionario/espelho-ponto-consolidado-funcionario.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT Z ZZ',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TopBarComponent,
    LoginComponent,
    UsuarioComponent,
    PerfilComponent,
    UnidadeComponent,
    ModalCadSetorComponent,
    FuncionarioComponent,
    CargoComponent,
    TurnoComponent,
    VinculoComponent,
    EscalaComponent,
    EscalaAcompanhamentoComponent,
    PermissoesComponent,
    DiarioFrequenciaComponent,
    ModalFrequenciaComponent,
    ModalAcompanhamentoComponent,
    ModalAprovaTrocaComponent,
    ModalTrocaSenhaComponent,
    MotivoAlteracaoComponent,
    AlteracaoEscalaComponent,
    EspelhoPontoComponent,
    FeriadoComponent,
    ModalCopiaEscalaComponent,
    NovoClienteComponent,
    ConfiguracaoComponent,
    ModalBiometriaComponent,
    ExportacaoPontoComponent,
    AprovarFotosComponent,
    PontosReprovadosComponent,
    DashboardRegistrosComponent,
    ListarFuncionariosComponent,
    EditarFuncionarioComponent,
    ModalApontamentoComponent,
    ModalDetalheIndicadorComponent,
    EspelhoPontoConsolidadoComponent,
    ModalDetalheIndicadorPontoComponent,
    ApontamentoMassaComponent,
    ModalApontamentoMassaComponent,
    AcompanhamentoPontosComponent,
    TemplateEscalaComponent,
    EspelhoPontoConsolidadoFuncionarioComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#0dcf61",
      secondaryColour: "#b1ff00",
      tertiaryColour: "#0dcf61"
    }),
    FormsModule ,
    HttpModule,
    ReactiveFormsModule,
    //MatFormComponentsModule,
    NgxViacepModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgAutoCompleteModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes),
    NgApexchartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCHBwvqZWplvUwmhJXa1s5yrLDJEfTF7_w'
    }),
    AutocompleteLibModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    Global,
    ApiService,
    NgxViacepService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'pt' },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
  entryComponents:[
    ModalCadSetorComponent,
    ModalFrequenciaComponent,
    ModalAcompanhamentoComponent,
    ModalAprovaTrocaComponent,
    ModalTrocaSenhaComponent,
    ModalCopiaEscalaComponent,
    ModalBiometriaComponent,
    ModalApontamentoComponent,
    ModalDetalheIndicadorComponent,
    ModalDetalheIndicadorPontoComponent,
    ModalApontamentoMassaComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
